import { graphql, Link } from "gatsby";
import * as React from "react";
import Layout from "../../components/layout";
import "../../css/lab.css";

export default ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;

  const cards = [
    {
      name: "box-resizer",
      desc: "box-resizer",
      url: "/lab/box-resizer",
      img: "https://images.pexels.com/photos/13292167/pexels-photo-13292167.jpeg?auto=compress&cs=tinysrgb&w=800",
    },
    {
      name: "我的组件库",
      desc: "lucas-design",
      url: "http://zhouweibin.top:8083",
      img: "https://images.pexels.com/photos/1476316/pexels-photo-1476316.jpeg?auto=compress&cs=tinysrgb&w=1600",
    },
  ];

  return (
    <Layout location={location} title={siteTitle}>
      <div>
        <h2>实验室</h2>
        <div className="lab-cards">
          {cards.map((card) => {
            return (
              <a
                key={card.name}
                className="lab-card"
                href={card.url}
                target="_blank"
              >
                <img src={card.img} alt="box-resizer" />
                <div className="lab-card-title">{card.name}</div>
              </a>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
